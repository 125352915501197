import { ref, getDownloadURL, uploadString } from "@firebase/storage";
import Resizer from "react-image-file-resizer";
import { Fstorage } from "../config/firebaseApp";
import {
  CardType,
  PaymentType,
  ProjectStateType,
  ProjectType,
  PurposeType,
  RewardType,
} from "../interface/interface";
export function formatDate(date: number | string, type: string) {
  var d = new Date(Number(date)),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (parseInt(month) < 10) month = "0" + month;
  if (parseInt(day) < 10) day = "0" + day;
  return [year, month, day].join(type);
}

export function formatDateKr(date: number | string) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (parseInt(month) < 10) month = "0" + month;
  if (parseInt(day) < 10) day = "0" + day;
  return `${year}년 ${month}월 ${day}일`;
}
export const ChangePaymentDay = (finish: number | string) => {
  const finishDate = new Date(finish);
  finishDate.setDate(finishDate.getDate() + 1);

  return `${formatDateKr(finishDate.getTime())} 10시`;
};
export const TextChangePaymentDay = (finish: number | string) => {
  const finishDate = new Date(finish);
  finishDate.setDate(finishDate.getDate() + 1);

  return `${formatDateKr(finishDate.getTime())} 오전 10시`;
};
export function makeComma(str: any) {
  const number = String(str).replace(/[^0-9]/gi, "");
  return String(number).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
}

export const telform = (e: string) => {
  const number = e.replace(/[^0-9-]/gi, "");
  const val = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  return val;
};

export function FileReaderBase64(file: string, path: string): Promise<string> {
  return new Promise((resolve, reject) => {
    if (file.includes("firebase")) {
      resolve(file);
    } else {
      const data = file.split(",")[1];
      const storageRef = ref(Fstorage, path);
      uploadString(storageRef, data, "base64").then((snapshot) => {
        console.log("Upload complete!");
        const downloadURLRef = ref(Fstorage, snapshot.ref.fullPath);
        getDownloadURL(downloadURLRef).then((downloadURL) => {
          resolve(downloadURL);
        });
      });
    }
  });
}

export const Fileread = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      const imageUrl = e?.target?.result;
      if (typeof imageUrl === "string") {
        resolve(imageUrl);
      } else {
        reject();
      }
    };
    reader.readAsDataURL(file);
  });
};

let selection: any;

export const SetSelection = (e: Range) => {
  selection = e;
};
export const GetSelection = () => {
  return selection;
};

export const ChangeDday = (finish: string) => {
  const finishDay = new Date(finish);
  finishDay.setDate(finishDay.getDate() + 1);
  const d_day = parseInt(
    String((finishDay.getTime() - Date.now()) / (1000 * 60 * 60 * 24))
  );
  return d_day > 0 ? `${d_day}일 남음` : "오늘 마감";
};

export function HHMM(date: number) {
  let d = new Date(date),
    hour = "" + d.getHours(),
    minit = "" + d.getMinutes();
  if (parseInt(hour) < 10) hour = "0" + hour;
  if (parseInt(minit) < 10) minit = "0" + minit;
  return `${hour}:${minit}`;
}

export function Dday(date1: Date, date2: Date) {
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
}

export const ProjectformatDate = (timestamp: string): any => {
  const localDate = new Date(timestamp);
  const nowDate = new Date();
  const isNow =
    nowDate.getFullYear() === localDate.getFullYear() &&
    nowDate.getMonth() === localDate.getMonth() &&
    nowDate.getDate() === localDate.getDate();
  if (isNow) {
    return HHMM(localDate.getTime());
  } else {
    const number = Dday(nowDate, localDate);
    if (number <= 30) {
      return `${number}일 전`;
    } else {
      return formatDate(localDate.getTime(), "-");
    }
  }
};

export const ChangePaymentData = (
  authProfile: {
    uid: string;
    email: string;
    nickname: string;
  },
  data: PaymentType,
  rewards: RewardType[],
  project: ProjectType,
  card: CardType
) => {
  const {
    donation,
    address: { basic, detail, zonecode },
    profile: { name, tel },
    etc,
    files,
  } = data;
  const amount =
    rewards.reduce((prev, next) => {
      const { productCost, delivery, count } = next;
      const result =
        Number(productCost) * Number(count) + Number(delivery ? delivery : 0);
      return prev + result;
    }, 0) + (donation ? donation : 0);
  const deliveryPrice = rewards.reduce((prev, next) => {
    const { delivery } = next;
    const result = delivery;
    return prev + result;
  }, 0);
  return {
    amount: Number(amount),
    deliveryprice: deliveryPrice,
    projectid: project.key,
    purpose: project.purpose,
    status: "ready",
    card,
    deliveryinfo: {
      address: {
        basic,
        detail,
        zonecode,
      },
      contentidx: rewards,
      profile: {
        name,
        tel,
      },
      etc,
      files,
    },
    profile: authProfile,
    rewardperiod: project.rewards.period,
    donation,
    paymentinfo: {
      callAmount: amount,
      email: authProfile.email,
      installmentCount: 0,
      memberName: authProfile.nickname,
      phone: tel,
      product: project.title,
    },
  };
};

export const ProjectStateKr = (state: ProjectStateType, finish: string) => {
  if (state === "fail") {
    return "펀딩 무산";
  }
  if (state === "finish" || state === "re-finish") {
    return "펀딩 성공";
  }
  return ChangeDday(finish);
};

export const OrderNumber = (rewards: RewardType[]) => {};

export const Resizing = (
  file: File,
  width: number,
  height: number
): Promise<any> => {
  return new Promise((res) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "JPEG",
      100,
      0,
      (uri) => res(uri),
      "base64"
    );
  });
};

export const reward_state = (state: string) => {
  if (state === "prepare") {
    return "심사 중";
  }
  if (state === "ready") {
    return "오픈 대기";
  }
  if (state === "reject") {
    return "심사 거절";
  }
  if (state === "funding") {
    return "오픈 중";
  }
  if (state === "finish") {
    return "오픈 마감";
  }
  if (state === "blind") {
    return "블라인드";
  }
  if (state === "save") {
    return "작성중";
  }

  return "";
};

export const StoryTime = (timestamp: number) => {
  const timeDifference = new Date().getTime() - timestamp;
  if (timeDifference / (60 * 60 * 1000) <= 24) {
    // 하루 이전 글인 경우 여기에 코드 작성

    // 밀리초를 분(minute)으로 변환
    const minutesDifference: number = timeDifference / (1000 * 60);

    // 밀리초를 시간(hour)으로 변환
    const hoursDifference: number = timeDifference / (1000 * 60 * 60);

    if (minutesDifference < 60) {
      return `${Math.floor(minutesDifference)} 분 전`;
    } else if (hoursDifference < 24) {
      return `${Math.floor(hoursDifference)} 시간 전`;
    }
  } else {
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    if (daysDifference < 2) {
      return "어제";
    } else {
      return formatDate(timestamp, "-");
    }
  }
  return formatDate(timestamp, "-");
};

export const CheckWritePath = (
  data: ProjectType,
  type: string,
  purpose: PurposeType
) => {
  if (type !== "basic") {
    const {
      title,
      images: { thumbnail },
      subImages,
      amount: { goal },
    } = data;
    if (
      title.length < 1 ||
      (purpose === "projects" && goal < 1) ||
      thumbnail.length < 1 ||
      subImages.filter((item) => item).length < 4
    ) {
      return {
        state: false,
        msg: "프로젝트 기본 정보를 입력해주세요",
        type: ".",
      };
    }
  }
  if (type !== "period" && type !== "category" && type !== "basic") {
    const {
      period: { start, finish },
      rewards: { period },
    } = data;
    if (start.length !== 10 || finish.length !== 10 || period.length !== 10) {
      return {
        state: false,
        msg: "프로젝트 기간을 설정해주세요",
        type: "./period",
      };
    }
  }
  if (type === "refund" || type === "content") {
    const {
      rewards: { content },
    } = data;
    if (content.length < 1) {
      return {
        state: false,
        msg: "리워드를 입력해주세요",
        type: "./reward",
      };
    }
  }
  if (type === "content") {
    const { refund } = data;
    if (refund.length < 1) {
      return {
        state: false,
        msg: "배송/환불 정책을 입력해주세요",
        type: "./refund",
      };
    }
  }
  return {
    state: true,
    msg: "",
    type: type === "basic" ? "." : `./${type}`,
  };
};
