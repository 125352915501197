import React from "react";
import { PqboxType, ProjectType } from "../../../../interface/interface";

type Props = {
  data: ProjectType | PqboxType;
  type: "project" | "pqbox";
};

const ThumbnailLook = ({
  data: {
    title,
    images: { thumbnail },
  },
  type,
}: Props) => {
  return (
    <div className="thumbnail-look">
      <div className="project-write-tag">썸네일 미리보기</div>
      <div
        className="look-image-section"
        style={
          thumbnail
            ? {
                backgroundImage: `url(${thumbnail})`,
              }
            : undefined
        }
      >
        {!thumbnail && (
          <>
            <img src="/assets/project/write/img-placeholder.svg" alt="" />
            <div className="title">대표 썸네일</div>
          </>
        )}
      </div>
      {type === "project" && (
        <>
          <div className="category">카테고리</div>
          <div className="title">
            {title ? (
              title
            ) : (
              <>
                프로젝트의 제목이 <br />
                입력되는 곳입니다
              </>
            )}
          </div>
          <div className="period">0000년 0월 0일 오픈 예정</div>
        </>
      )}
    </div>
  );
};

export default ThumbnailLook;
