import React, { Dispatch, SetStateAction } from "react";

type Props = {
  Active: () => void;
  setCancel?: Dispatch<SetStateAction<string | undefined>>;
};

const WidthDrawPopup = ({ Active, setCancel }: Props) => {
  return (
    <div className="remove-popup">
      <div className="title">탈퇴하시겠습니까?</div>
      <div className="sub">탈퇴후에는 계정을 다시 이용하실 수 없습니다.</div>
      <div className="btn-wrapper">
        <button
          className="active-btn"
          onClick={() => {
            Active();
          }}
        >
          탈퇴하기
        </button>
        <button
          className="deactive-btn"
          onClick={() => {
            if (setCancel) {
              setCancel(undefined);
            }
          }}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default WidthDrawPopup;
