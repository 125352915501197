import React, { useCallback, useEffect, useReducer, useState } from "react";
import ProjectPayment from "../ProjectPayment";
import { UserAgentType } from "../../../common/common.inerface";
import { PaymentReducer } from "../../../lib/reducer";
import { PaymentIntial } from "../../../lib/initial";
import { useDispatch, useSelector } from "react-redux";
import { AuthType } from "../../../reducers/auth";
import { RootState } from "../../../reducers";
import { useLocation, useNavigate } from "react-router-dom";
import UseCard from "../../../hooks/UseCard";
import { CardType, RewardType } from "../../../interface/interface";
import { ChangePaymentData } from "../../../lib/lib";
import { PaymentFixApi } from "../../../api/project_api";
import { LoadingChange, ToastChange } from "../../../reducers/config";

type Props = { userAgent: UserAgentType };

const PaymentFixContainer = ({ userAgent }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const UserData = useSelector<RootState, AuthType>((state) => state.auth);
  const { __initCard } = UseCard({ uid: UserData.uid ? UserData.uid : "" });
  const [nowCard, setNowCard] = useState<CardType | undefined>(
    UserData.card ? UserData.card[0] : undefined
  );
  const [RewardData, setRewardData] = useState<RewardType[]>(
    location.state.RewardData
  );
  const [originalData, setOriginalData] = useState<any>("");
  const [state, setRducer] = useReducer(
    PaymentReducer,
    undefined,
    PaymentIntial
  );

  const SubmitPayment = useCallback(async () => {
    dispatch(LoadingChange(true));
    const {
      data,
      paymentInfo: { donation },
    } = originalData;
    if (nowCard) {
      const {
        uid,
        profile: { email, nickname, image },
      } = UserData;
      const usr_data: any = {
        uid,
        email,
        nickname,
        image,
      };
      console.log(RewardData);
      const obj = ChangePaymentData(usr_data, state, RewardData, data, nowCard);
      console.log(obj);
      const result = await PaymentFixApi(
        obj,
        RewardData,
        location.state.gateKey
      );
      if (result.state) {
        navigate("/my/list");
        dispatch(
          ToastChange({
            active: true,
            content: `${
              data.purpose === "projects" ? "펀딩" : "오더"
            }를 수정하였습니다`,
          })
        );
      } else {
        dispatch(ToastChange({ active: true, content: result.msg }));
      }
      dispatch(LoadingChange(false));
    }
  }, [UserData, nowCard, state, location, RewardData, originalData]);
  const Valid = useCallback(() => {
    const {
      profile: { name, tel },
      address: { basic, zonecode },
    } = state;
    if (basic.length < 1 || zonecode.length < 1) {
      return {
        state: false,
        msg: "주소를 입력해주세요",
      };
    }
    if (name.length < 1) {
      return {
        state: false,
        msg: "이름을 입력해주세요",
      };
    }
    if (tel.length < 1) {
      return {
        state: false,
        msg: "연락처를 입력해주세요",
      };
    }
    return {
      state: true,
    };
  }, [state]);

  useEffect(() => {
    const obj = location.state;
    if (obj.paymentInfo) {
      setRducer({
        type: "dispatch/all",
        payload: obj.paymentInfo,
      });
    }
    setOriginalData(obj);
    return () => {};
  }, [location]);

  useEffect(() => {
    if (UserData.card) {
      setNowCard(UserData.card[0]);
    }
    return () => {};
  }, [UserData.card]);

  if (!location.state) {
    return <></>;
  }
  return (
    <ProjectPayment
      data={location.state.data}
      dispatch={setRducer}
      state={state}
      RewardData={RewardData}
      cards={UserData.card ? UserData.card : []}
      __initCard={__initCard}
      nowCard={nowCard}
      setNowCard={setNowCard}
      userAgent={userAgent}
      Valid={Valid}
      SubmitPayment={SubmitPayment}
      uid={UserData.uid}
      setRewardData={setRewardData}
      email={UserData.profile.email}
    />
  );
};

export default PaymentFixContainer;
