import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../common/common.inerface";
import { useLocation } from "react-router-dom";
type Props = {
  userAgent: UserAgentType;
};

const Footer = ({ userAgent }: Props) => {
  const pathname = useLocation().pathname;
  if (
    pathname.includes("signup") ||
    pathname.includes("password") ||
    pathname.includes("write")
  ) {
    return <></>;
  }
  return (
    <footer className="footer-section">
      <div className="footer-content-wrapper">
        <div className="footer-title-section">
          <b className="footer-title">
            TOY 프로젝트 펀딩 플랫폼 프로젝트퀘스천
          </b>
          {userAgent !== "mb" && (
            <div className="footer-sns-wrapper">
              {sns_layouts.map(({ type, link }, idx) => {
                return (
                  <a
                    href={link}
                    key={idx}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={`/assets/footer/${type}.svg`} alt={type} />
                  </a>
                );
              })}
            </div>
          )}
        </div>
        <div className="footer-menu-section">
          {footer_menus.map(({ name, url }, idx) => {
            return (
              <a rel="noopener noreferrer" target="_blank" href={url} key={idx}>
                {name}
              </a>
            );
          })}
        </div>
        <div className="footer-content-section">
          <div className="footer-logo">
            <img src="/assets/footer/logo.svg" alt="프로젝트 퀘스천" />
            {userAgent === "pc" && (
              <div className="copyright">
                Copyright (주)프로젝트퀘스천, All Rights Reserved
              </div>
            )}
          </div>
          <div className="footer-content">
            <div className="footer-info">
              (주)프로젝트퀘스천 | 대표 최은원 | 사업자등록번호{" "}
              {userAgent === "mb" && <br />} 797-81-01908 <br />
              통신판매업등록번호 2024-서울중구-0103 호
            </div>
            <div className="footer-list">
              {content_layouts.map(({ icon, content }, idx) => {
                return (
                  <div key={idx} className="list-line">
                    <img src={`/assets/footer/${icon}.svg`} alt={icon} />
                    <div className="line-content">{content}</div>
                  </div>
                );
              })}
            </div>
            <div className="footer-sub">
              프로젝트퀘스천은 플랫폼 제공자로서 프로젝트의 당사자가 아니며,
              직접적인 통신판매를 진행하지 않습니다.
              <br /> 프로젝트의 완수 및 리워드 제공의 책임은 해당 프로젝트의
              창작자에게 있으며,
              <br className="mb-br" /> 프로젝트와 관련하여 후원자와 발생하는
              법적분쟁에 대한 책임은 창작자가 부담합니다.
            </div>
            {userAgent !== "pc" && (
              <div className="copyright">
                Copyright (주)프로젝트퀘스천, All Rights Reserved
              </div>
            )}
            {userAgent === "mb" && (
              <div className="footer-sns-wrapper">
                {sns_layouts.map(({ type, link }, idx) => {
                  return (
                    <a
                      href={link}
                      key={idx}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img src={`/assets/footer/${type}.svg`} alt={type} />
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const sns_layouts = [
  {
    type: "facebook",
    link: "https://www.facebook.com/Project-Question-100100008092123/",
  },
  {
    type: "instagram",
    link: "https://www.instagram.com/project.question/",
  },
  {
    type: "youtube",
    link: "https://www.youtube.com/channel/UCC2S-dGBZ9qI8F3tayPWr3A?view_as=subscriber",
  },
  {
    type: "naver",
    link: "https://blog.naver.com/project_question",
  },
];
const footer_menus = [
  {
    name: "PQ가이드",
    url: "https://drive.google.com/file/d/1mo-xmygG0b-lMyyXs0D_a0fmaQxByKD5/view",
  },
  {
    name: "서비스소개서",
    url: "https://drive.google.com/file/d/1Ng5XQ4vX8EZhY9AK3fWccWmViZgruTo3/view",
  },
  {
    name: "약관 및 정책",
    url: "/policy/terms",
  },
  {
    name: "문의하기",
    url: "https://projectquestion.com/ask",
  },
];

const content_layouts = [
  {
    icon: "pin",
    content: "서울특별시 중구 세종대로 14 GRAND CENTRAL 5F 513호",
  },
  {
    icon: "call",
    content: "0507-1453-5492",
  },
  {
    icon: "mail",
    content: "pq_cs@projectquestion.com",
  },
];
