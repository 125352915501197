import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { LoadingChange, ToastChange } from "../reducers/config";
import { clientApi } from "../api/config";
import { cstid, custKey, paymentUrl, reffor } from "../config/paymentKey";
import { AuthCard } from "../reducers/auth";

type Props = {
  uid: string;
};

const UseCard = ({ uid }: Props) => {
  const dispatch = useDispatch();
  const getCards = useCallback(() => {
    dispatch(LoadingChange(true));
    clientApi
      .get("/user/card", {
        params: {
          uid: uid,
        },
      })
      .then(({ data: { state, data } }) => {
        if (state) {
          dispatch(AuthCard(data));
        }
        dispatch(LoadingChange(false));
      })
      .catch((error) => {
        console.error(error);
        dispatch(LoadingChange(false));
      });
  }, [uid, dispatch]);

  const __updateCard = useCallback(
    async (card: any) => {
      try {
        if (card.PCD_PAY_RST === "success") {
          dispatch(LoadingChange(true));
          const {
            data: { state },
          } = await clientApi.post("/user/card", {
            uid: uid,
            card,
          });
          dispatch(LoadingChange(false));
          if (state) {
            getCards();
          }
        } else {
          console.log("카드 등록이 실패하였습니다.");
        }
      } catch (error) {
        dispatch(LoadingChange(false));
        console.error(error);
      }
    },
    [uid, dispatch, getCards]
  );

  const deleteCard = useCallback(
    async (cardId: string) => {
      try {
        dispatch(LoadingChange(true));
        const {
          data: { state, msg },
        } = await clientApi.delete(`/user/card`, {
          data: {
            uid: uid,
            card: cardId,
          },
        });
        if (state) {
          getCards();
        }
        dispatch(ToastChange({ active: true, content: msg }));
        dispatch(LoadingChange(false));
      } catch (error) {
        dispatch(LoadingChange(false));
      }
    },
    [dispatch, getCards]
  );

  const __initCard = useCallback(() => {
    dispatch(LoadingChange(true));
    const form = new FormData();
    form.append("cst_id", cstid);
    form.append("custKey", custKey);
    fetch(paymentUrl, {
      method: "POST",
      headers: {
        referer: reffor,
      },
      body: form,
    })
      .then((res) => res.json())
      .then((res) => {
        const { AuthKey, return_url } = res;
        const info = {
          PCD_AUTH_KEY: AuthKey,
          PCD_CARD_VER: "01",
          PCD_PAY_TYPE: "card",
          PCD_PAY_URL: return_url,
          PCD_PAY_WORK: "AUTH",
          callbackFunction: __updateCard,
        };
        window.PaypleCpayPopup(info);
        dispatch(LoadingChange(false));
      })
      .catch((err) => {
        dispatch(LoadingChange(false));
      });
  }, [__updateCard]);

  return { __initCard, getCards, __updateCard, deleteCard };
};

export default UseCard;
