import { Dispatch } from "@reduxjs/toolkit";
import React, { ChangeEvent, useCallback } from "react";
import { LoadingChange } from "../../../reducers/config";
import { AppDispatch } from "../../..";
import { FileReaderBase64, Fileread, GetSelection } from "../../../lib/lib";
import { PurposeType } from "../../../interface/interface";

const dummy = [
  { img: "temp", type: "IMAGE" },
  { img: "youtube", type: "YOUTUBE" },
  { img: "link", type: "LINK" },
  { img: "file", type: "FILE" },
];

const rewards_layouts = [
  { img: "temp", type: "IMAGE" },
  // { img: "news", type: "NEWS" },
  // { img: "video", type: "VIDEO" },
  { img: "youtube", type: "YOUTUBE" },
  { img: "link", type: "LINK" },
  { img: "file", type: "FILE" },
];
function Insert({
  setIsUp,
  dispatch,
  dataKey,
  purpose,
}: {
  setIsUp: React.Dispatch<
    React.SetStateAction<{
      status: boolean;
      type: string;
    }>
  >;
  dispatch: AppDispatch;
  dataKey: string;
  purpose: PurposeType | "rewards";
}) {
  const arr = purpose === "rewards" ? rewards_layouts : dummy;
  const __fileReader = useCallback(
    (file: File) => {
      return new Promise((resolv, reject) => {
        Fileread(file).then((data) => {
          FileReaderBase64(data, `/${purpose}/${dataKey}/${file.name}`).then(
            (url) => {
              const screen = document.getElementById("screen");
              if (screen) {
                const ima = document.createElement("img");
                const text = document.createElement("div");
                ima.className = "template-img";
                ima.src = url;
                const selection = GetSelection();
                if (selection) {
                  selection.collapse(false);
                  selection.insertNode(text);
                  selection.insertNode(ima);
                } else {
                  screen.appendChild(ima);
                  screen.appendChild(text);
                }
                resolv(true);
              }
            }
          );
        });
      });
    },
    [dataKey, purpose]
  );
  const __imageUpdate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(LoadingChange(true));
      if (e.target.files) {
        let fileList = Object.values(e.target.files);
        Promise.all(
          fileList.map((item) => {
            return __fileReader(item);
          })
        ).then(() => {
          dispatch(LoadingChange(false));
        });
      }
    },
    [__fileReader, dispatch]
  );
  return (
    <div className="insert-wrapper">
      {arr.map(({ img, type }, idx) => {
        return (
          <label
            className="test-img"
            key={idx}
            onClick={() => {
              if (type !== "IMAGE" && type !== "SUMMARY") {
                setIsUp({
                  status: true,
                  type: type,
                });
              }
            }}
          >
            {type === "IMAGE" ? (
              <input
                type="file"
                style={{ opacity: 0 }}
                multiple={true}
                accept="image/x-png,image/gif,image/jpeg"
                onChange={__imageUpdate}
              />
            ) : undefined}
            <img src={`/assets/editor/${img}.svg`} alt={`${img}`} />
          </label>
        );
      })}
    </div>
  );
}

export default Insert;
